import { ToWords } from "to-words";

/**
 * @param {string|number} input
 * @returns {object[]}
 */
export function convertTo4(input) {
  const steps = [];

  while (true) {
    steps.push(input);

    let words;
    try {
      words = new ToWords({ localeCode: "en-US" }).convert(input);
    } catch {
      words = input;
    }
    if (words.length === 4) {
      steps.push(4);
      return steps;
    }
    input = words.length;
  }
}
